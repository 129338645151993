<template>
  <div>
    <form
      id="form164"
      ref="newsletterForm"
      :name="formName"
      action=""
      class="elq-form relative"
    >
      <label class="block h5 text-white mb-3" for="field0"
        >Subscribe to our email updates
      </label>
      <div class="flex w-full">
        <input
          id="field0"
          v-model="emailAddress"
          name="emailAddress"
          type="text"
          placeholder="Email address"
          :disabled="submitStatus === 'sending'"
          class="flex-1 max-w-full text-black border shadow p-3 placeholder-gray-400 leading-6 focus:ring focus:ring-offset-0 focus:ring-opacity-50"
          :class="[
            { 'cursor-not-allowed': submitStatus === 'sending' },
            submitStatus === 'invalid'
              ? 'border-brand-ochre-400 focus:border-brand-blue-400 focus:ring-brand-ochre-400'
              : 'focus:border-brand-blue-400 focus:ring-brand-blue-400'
          ]"
        />
        <button
          :disabled="submitStatus === 'sending'"
          class="submit-button font-bold text-base px-5 py-2 flex flex-row items-center space-x-2 shadow-md border"
          :class="
            submitStatus === 'sending'
              ? 'bg-brand-blue-200 border-brand-blue-300 hover:bg-brand-blue-200 focus:bg-brand-blue-200 cursor-not-allowed'
              : 'bg-brand-blue-500 border-brand-blue-400 hover:bg-brand-blue-700 focus:bg-brand-blue-700'
          "
          @click.prevent="postToEloqua"
        >
          Submit
        </button>
      </div>
    </form>
    <div
      v-if="submitStatus === 'sending'"
      class="flex items-center px-4 py-2 border-t border-gray-800"
    >
      <Spinner class="mr-2" /> Sending
    </div>
    <div
      v-if="submitStatus === 'invalid'"
      class="py-2 border-t border-gray-800 text-brand-ochre-200"
    >
      <p v-if="v$.emailAddress.email.$invalid">
        Please enter valid email address
      </p>
      <p v-if="v$.emailAddress.required.$invalid">Email address is required</p>
    </div>
    <div
      v-if="submitStatus === 'submitted'"
      class="bg-brand-green-500 px-4 py-2 border-t border-gray-800"
    >
      Your email submitted, Thanks
    </div>
    <div
      v-if="submitStatus === 'error'"
      class="bg-brand-ochre-500 px-4 py-2 border-t border-gray-800"
    >
      Something went wrong please try again later
    </div>
  </div>
</template>

<script>
import { useMainStore } from '~/stores/main'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default defineNuxtComponent({
  name: 'AppFooterNewsLetter',
  setup() {
    const { origin } = useMainStore()
    const v$ = useVuelidate()
    const config$ = useRuntimeConfig().public
    const route$ = useRoute()
    const eloquaForm = useEloquaForm()

    return { origin, v$, config$, route$, eloquaForm }
  },
  data() {
    return {
      formName: 'copyOf201812Newsletter-1544396612843',
      emailAddress: '',
      pageSourceTitle: '',
      pageSourceURI: '',
      submitStatus: null,
      token: ''
    }
  },
  validations() {
    return {
      emailAddress: {
        required,
        email
      }
    }
  },
  computed: {
    formFields() {
      return [
        { name: 'elqCampaignId', value: '', hidden: true },
        {
          name: 'emailAddress',
          value: this.emailAddress,
          label: 'Subscribe to our email updates'
        },
        {
          name: 'hiddenField2',
          value: 'Website Newsletter Footer',
          hidden: true
        },
        { name: 'studyLocation', value: '', hidden: true },
        { name: 'studyArea', value: '', hidden: true },
        { name: 'rTO1', value: '', hidden: true },
        { name: 'pageSourceTitle', value: this.pageSourceTitle, hidden: true },
        { name: 'pageSourceURI', value: this.pageSourceURI, hidden: true },
        { name: 'campaignName', value: '', hidden: true }
      ]
    },
    formData() {
      const fields = this.formFields.map((field) => {
        return `&${field.name}=${field.value}`
      })
      return (
        `elqFormName=${this.formName}&elqSiteID=${this.config$.eloquaSiteId}` +
        fields.join('')
      )
    }
  },
  mounted() {
    // nextTick to force update on client side rendering
    this.$nextTick(() => {
      this.pageSourceTitle = document.title
      this.pageSourceURI = this.config$.siteUrl + this.route$.fullPath
    })
  },
  methods: {
    async postToEloqua() {
      const isFormCorrect = await this.v$.$validate()
      if (isFormCorrect) {
        this.submitStatus = 'sending'
        try {
          const result = await this.eloquaForm.submit(
            'newsletter',
            this.formData
          )

          if (result.code === 401) {
            throw new Error(result.message)
          } else if (result.code !== 200) {
            throw new Error('Something went wrong')
          }

          this.submitStatus = 'submitted'
          this.emailAddress = ''
        } catch (err) {
          this.submitStatus = 'error'
          console.log('Eloqua submission failed: ' + err)
        } finally {
          // reset form after timeout
          setTimeout(() => {
            this.submitStatus = null
          }, 5000)
        }
      } else {
        this.submitStatus = 'invalid'
      }
    }
  }
})
</script>
